<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                            exact>
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
            <v-col cols="12">
                <v-tabs v-model="selectedTab"
                class="round_top col-sm-no-padding ml-5 pt-5"
                icons-and-text
                show-arrows>
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab v-for="(tab, index) in userTabs" :key="index" active-class="active-tab">
                        {{ tab.name }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="selectedTab">
                    <v-tab-item v-for="(tab, index) in userTabs" :key="index">
                        <component :is="tab.component"></component>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <content-footer />
    </v-container>
</template>
<script>
import ContentFooter from '../layouts/Footer.vue'
import VueCropper from 'vue-cropperjs'
import {KkaApproval} from "../backend-api/kka/approval/kka_approval"
import prAppOne from "../views/approval/kka/pr_app_one.vue"
import prAppTwo from "../views/approval/kka/pr_app_two.vue"
import prAppThree from "../views/approval/kka/pr_app_three.vue"

export default {
    components: {
        ContentFooter, VueCropper, prAppOne, prAppTwo, prAppThree
    },
    data() {
        return {
            tab: '',
            userTabs: [],
            tzoffsetConvert: new Date().getTimezoneOffset() * 60000,
            trType: 'PR',
            selectedTab: 0, // Set the initial tab index
            step: 1,
            groupRights:[],
            breadcumbs: [
                {
                    text: 'KKA',
                    disabled: false,
                    href: '/admin/kka',
                },
                {
                    text: 'Purchase Request Approval',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
        }
    },
    mounted() {
        this.$store.dispatch('setOverlay', true)
        this.$store.dispatch('setOverlay', false)
        this.getApproveLevel()
    },
    methods: {
        async getApproveLevel(){
            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                "tr_type": this.trType,
                "aprv_user": `${this.$store.getters.Idemploye}`
            }
            var dataLocal=[]
            var respData = await KkaApproval.getAprvLevel('', reqBody)
            if (respData.status === 200) {
                console.log(respData.data)
                dataLocal = respData.data.data
                const userPermissions = dataLocal.map(obj => Number(obj.aprv_level));
                userPermissions.forEach(tabNumber => {
                    if (tabNumber === 1) {
                        this.userTabs.push({
                        name: 'Approval 1',
                        component: prAppOne,
                        });
                    } else if (tabNumber === 2) {
                        this.userTabs.push({
                        name: 'Approval 2',
                        component: prAppTwo,
                        });
                    } else if (tabNumber === 3) {
                        this.userTabs.push({
                        name: 'Approval 3',
                        component: prAppThree,
                        });
                    }
                });
                if (this.userTabs.length > 0) {
                    this.selectedTab = 0;
                }
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
            }
        },
    },

}
</script>